body {
    font-family: sans-serif;
}

.tab-decoration-0 {
    border-top-color: #e3a600 !important;
}

.tab-decoration-1 {
    border-top-color: #b8b600 !important;
}

.tab-decoration-2 {
    border-top-color: #75c500 !important;
}

.tab-decoration-3 {
    border-top-color: #00ca81 !important;
}

.tab-decoration-4 {
    border-top-color: #00c6b2 !important;
}

.tab-decoration-5 {
    border-top-color: #00c3d2 !important;
}

.tab-decoration-6 {
    border-top-color: #13bdff !important;
}

.tab-decoration-7 {
    border-top-color: #a6a9ff !important;
}

.tab-decoration-8 {
    border-top-color: #e390ff !important;
}

.tab-decoration-9 {
    border-top-color: #ff86dc !important;
}

.tab-decoration-10 {
    border-top-color: #ff8eaf !important;
}

.tab-decoration-11 {
    border-top-color: #ff9470 !important;
}

$primary: #5716e3;
$dark: #3A0F96;
$secondary: #1f7ead;

@import "../../node_modules/bootstrap/scss/bootstrap";
