
.bash-output {
    background-color: #222;
    color: white;
    border: 1px solid black;
    font-family: monospace;
    overflow: auto;
}

.code-editor {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
}

.flex-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    min-width: 0;
    min-height: 0;
    max-height: 100%;
}

.fill-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-width: 0;
    min-height: 0;
}

    .fill-content .tab-content > .active {
        display: flex;
        overflow: hidden;
    }

.nav-link.small-tabs {
    padding: 0.25rem 0.5rem;
}

.fill-row {
    flex: 1 1 auto;
    min-width: 0;
    min-height: 0;
}

.fixed-content {
    flex: 0 1 auto;
}

.full-size {
    height: 100%;
}

.watermark {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: 0;
    min-width: 0;
}

.watermark2 {
    height: 100%;
    width: auto;
    min-height: 0;
    min-width: 0;
}

.one-page {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
}
.nav-tabs .nav-link.active.out-tab {
    border-top-width: 4px;
    padding-bottom: 5px;
}
.nav-tabs .nav-link.out-tab {
    padding-bottom: 3px;
}

.ratio-line {
    color: forestgreen !important;
    font-style: oblique;
    vertical-align: bottom;
    font-size: 90%;
}

.asm-keyword {
    color: blue !important;
}

.asm-title {
    color: red !important;
    font-size: 100%;
}

.line-img {
    height: 1.3em;
}

.modal-90w {
    max-width: 90%;
}

.modal-60w {
    max-width: 60%;
}

.close-button {
    padding: 0 0 0 5px;
    border: none;
    background: none;
}

.card-body.packed-card {
    padding: 0 0 0 0;
    min-width: 0;
    min-height: 0;
    display: flex;
}

.rainbow-decoration {
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: -webkit-gradient(linear, left center, right center, color-stop(0.00, red), color-stop(16%, orange), color-stop(32%, yellow), color-stop(48%, green), color-stop(60%, blue), color-stop(76%, indigo), color-stop(1.00, violet));
}

.navbar {
    padding: 1rem;
}

.center-icon{
    display: flex;
    align-items: center;
}